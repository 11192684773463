import React from "react";
import styled from "@emotion/styled";
import { Container, Flex, FlexItem, Media } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import { css } from "@emotion/react";
import { Typography } from "@ryerson/frontend.typography";
import { BulletPoint, ResilientSupplyChainProps } from "../ResilientSupplyChain";
import CardComponentRefactor from "@components/Shared/CardComponent/CardComponentRefactor";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import { useApplication } from "@ryerson/frontend.application";

const LanguagePositions = {
	en: {
		title1Left: "-50px",
		title2Left: "-85px",
		title1LeftSmaller: "-125px",
		title2LeftSmaller: "-150px",
        title2Top: "300px",
	},
	fr: {
		title1Left: "25px",
		title2Left: "-165px",
		title1LeftSmaller: "-180px",
		title2LeftSmaller: "-390px",
        title2Top: "410px",
	},
	es: {
		title1Left: "-50px",
		title2Left: "-130px",
		title1LeftSmaller: "-190px",
		title2LeftSmaller: "-240px",
        title2Top: "350px",
	},
};

const ResilientSupplyChainDesktop: React.FC<ResilientSupplyChainProps> = ({ ...props }) => {
	const {
		localization: { language },
	} = useApplication();
	const { title1Left, title2Left, title1LeftSmaller, title2LeftSmaller, title2Top } =
		LanguagePositions[language];
	const { theme } = useTheme();
	const content = props.content;
	const background = theme.colors[props.background || "primary"].background;
	const breakPointXL = theme.breakpoints.xl;
	const { lightGray } = theme.colors.primary;

	const ImageContainer = styled.div`
		position: absolute;
	`;

	const SectionImageContainer = styled.div`
		z-index: 0;
		display: block;
		position: absolute;
		background-position: 0 0;
		background-repeat: no-repeat;
		background-size: contain;
		width: 740px;
		height: 920px;
		@media only screen and (max-width: ${breakPointXL}) {
			width: 540px;
			height: 720px;
		}
		background-image: url(${props.imageUrl});
	`;

	const SectionContainer = styled.div`
		width: 360px;
		padding-top: 80px;
	`;

	const bulletPointContainer = css`
		position: relative;
		padding: 20px 0;
		min-height: 315px;
		color: ${lightGray};
		border-bottom: 1px solid ${lightGray};
	`;

	const BulletIndex = styled.div`
		position: absolute;
		right: 0;
		top: 0;
		z-index: 0;
		opacity: 0.3;
		font-size: 300px;
	`;

	const bulletTextStyle = css`
		z-index: 1;
		padding-right: 100px;
	`;

	const RootContainer = styled.div`
		margin-top: 60px;
		padding-bottom: 60px;
		max-width: 1440px;
		margin-left: auto;
		margin-right: auto;
		min-height: 800px;
		@media only screen and (max-width: ${breakPointXL}) {
			min-height: 650px;
		}
	`;

	const BaseContainer = styled.div`
		position: relative;
		width: 100%;
		background: linear-gradient(
			180deg,
			${background} 81.5%,
			${theme.colors.primary.background} 18.5%
		);
	`;

	const DescriptionContent = styled.div`
		padding-bottom: 30px;
	`;

	const QuestionsContainer = styled.div`
		width: 450px;
		border-bottom: 1px solid ${lightGray};
	`;

	const cardItem = css`
		border: 1px solid rgba(239, 242, 245, 0.2);
		width: 100%;
		&:not(:last-child) {
			margin-right: 4%;
		}
	`;

	//Parallax Title
	const TitleContainer = styled.div`
		position: absolute;
		top: 0;
		left: 500px;
		height: 100%;
		width: 150px;
		z-index: 2;
	`;
	const Title1 = styled.div`
		position: absolute;
		transform: rotate(270deg);
		font-size: 100px;
		letter-spacing: -0.04em;
		left: -50px;
		width: max-content;
		top: 750px;
		z-index: 2;
		${(props: any) => {
			const { theme } = props;
			return css`
				font-family: ${theme.typography.fontFamilies.headings};
				color: ${theme.colors.primary.white};
				@media (max-width: ${theme.breakpoints.xl}) {
					font-size: 60.5px;
					top: 375px;
				}
			`;
		}}
	`;

	const Title2 = styled.div`
		position: absolute;
		left: 350px;
		top: ${title2Top};
		width: max-content;
		// position: absolute;
		transform: rotate(270deg);
		font-size: 100px;
		letter-spacing: -0.04em;
		${(props: any) => {
			const { theme } = props;
			return css`
				font-family: ${theme.typography.fontFamilies.headings};
				color: ${theme.colors.primary.white};
				@media (max-width: ${theme.breakpoints.xl}) {
					top: 145px;
					font-size: 60.5px;
				}
			`;
		}}
	`;

	return (
		<ParallaxProvider>
			<BaseContainer className="base-container">
				<RootContainer>
					<ImageContainer>
						<SectionImageContainer />

						<TitleContainer>
							<Media greaterThanOrEqual="xl">
								<Parallax y={["-120px", "-250px"]}>
									<Title1
										css={css`
											left: ${title1Left};
											@media only screen and (max-width: ${theme.breakpoints
													.xl}) {
												left: ${title1LeftSmaller};
											}
                                            ${props.titleFontSize ? `font-size: ${props.titleFontSize};` : ""}
										`}
										theme={theme}
									>
										{props.title1}
									</Title1>
								</Parallax>
								<Parallax y={["-125px", "-10px"]}>
									<Title2
										css={css`
											left: ${title2Left};
											@media only screen and (max-width: ${theme.breakpoints
													.xl}) {
												left: ${title2LeftSmaller};
											}
                                            ${props.titleFontSize ? `font-size: ${props.titleFontSize};` : ""}
										`}
										theme={theme}
									>
										{props.title2}
									</Title2>
								</Parallax>
							</Media>
							<Media lessThan="xl">
								<Parallax y={["-55px", title1LeftSmaller]}>
									<Title1
										css={css`
											left: ${title1LeftSmaller};
										`}
										theme={theme}
									>
										{props.title1}
									</Title1>
								</Parallax>
								<Parallax y={["-65px", "10px"]}>
									<Title2
										css={css`
											left: ${title2LeftSmaller};
										`}
										theme={theme}
									>
										{props.title2}
									</Title2>
								</Parallax>
							</Media>
						</TitleContainer>
					</ImageContainer>

					<Container>
						<Flex justifyContent="space-between" alignContent="flex-end">
							<Flex direction="column" justifyContent="flex-end">
								<QuestionsContainer>
									<DescriptionContent>
										<Typography
											size="lg"
											color={theme.colors.primary.lighterGray}
										>
											{props.questionsContext}
										</Typography>
									</DescriptionContent>
									<DescriptionContent>
										<Typography size="lg" color={theme.colors.primary.white}>
											{props.question1}
										</Typography>
									</DescriptionContent>
									<DescriptionContent>
										<Typography type="tertiary">
											{props.answer1}
											<br />
											<br />
											{props.answerComment}
										</Typography>
									</DescriptionContent>
									<DescriptionContent>
										<Typography color={theme.colors.primary.white}>
											{props.question2}
										</Typography>
									</DescriptionContent>
								</QuestionsContainer>
							</Flex>
							<SectionContainer>
								<FlexItem>
									<DescriptionContent>
										<Typography size="lg" type="tertiary">
											{props.description1}
										</Typography>
									</DescriptionContent>
									<DescriptionContent>
										<Typography size="lg" type="tertiary">
											{props.description2}
										</Typography>
									</DescriptionContent>
									{content &&
										content.map((item: BulletPoint, index: number) => (
											<Flex
												alignItems="flex-end"
												css={bulletPointContainer}
												key={index}
											>
												<Flex direction="column" css={bulletTextStyle}>
													<FlexItem>
														<Typography variant="h4" type="tertiary">
															{item.subTitle}
														</Typography>
													</FlexItem>
													<FlexItem>
														<Typography
															variant="p"
															size="sm"
															type="tertiary"
															css={{ marginTop: "30px" }}
														>
															{item.context}
														</Typography>
													</FlexItem>
												</Flex>
												<BulletIndex>
													<Typography>{index + 1}</Typography>
												</BulletIndex>
											</Flex>
										))}
								</FlexItem>
							</SectionContainer>
						</Flex>
						<Flex justifyContent="space-between" css={{ paddingTop: "150px" }}>
							{props?.cardItems?.map((item, index: number) => (
								<FlexItem key={index} css={cardItem}>
									<CardComponentRefactor
										imageUrl={item.imageUrl}
										title={item.title}
										context={item.context}
										url={item.url}
									/>
								</FlexItem>
							))}
						</Flex>
					</Container>
				</RootContainer>
			</BaseContainer>
		</ParallaxProvider>
	);
};

export default ResilientSupplyChainDesktop;
